import React, { useEffect, MouseEvent, TouchEvent } from 'react'
import { makeStyles, Box } from '@material-ui/core'
import { Helmet } from 'react-helmet'

import { Theremin } from 'components/logos/theremin'
import { isBrowser } from 'utilities/gatsby'

const useStyles = makeStyles({
  container: {
    width: '100%',
    height: '100%',
    touchAction: 'pan-left pan-right pan-up pan-down',
  }
})

export const ThereminEgg = () => {
  const classes = useStyles()
  let cntxt: AudioContext
  let oscillator: OscillatorNode
  let gain: GainNode


  useEffect(() => {
    if (isBrowser()) {
      // @ts-ignore
      cntxt = new (window.AudioContext || window.webkitAudioContext)()
      gain = cntxt.createGain()
      oscillator = cntxt.createOscillator()
      oscillator.type = 'sine'
      oscillator.start(cntxt.currentTime)
    }

    return () => {
      cntxt.close()
    }
  })

  const scale = (num: number, in_min: number, in_max: number, out_min: number, out_max: number) => {
    return (num - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
  }

  const handleMouseMove = (event: MouseEvent) => {
    const gainVal = scale(event.clientY, 110, 1020, .999, 0.0001)
    const width = window && window.innerWidth;
    const pitch = scale(event.clientX, 0, width, 100, 1024)
    oscillator.frequency.value = pitch
    gain.gain.value = gainVal
  }

  const handleTouchMove = (event: TouchEvent) => {
    const gainVal = scale(event.touches[0].clientY, -60, 800, .999, 0.0001)
    const width = window && window.innerWidth;
    const pitch = scale(event.touches[0].clientX, 0, width, 100, 1024)
    oscillator.frequency.value = pitch
    gain.gain.value = gainVal
  }

  const mouseUp = () => {
    if (oscillator) {
      try {
        oscillator.disconnect();
      } catch (e) {}
    }
  }

  const mouseDown = async () => {
    if (oscillator) {
      if (cntxt.state === 'suspended') {
        await cntxt.resume()
      }
    
      try {
        oscillator.connect(gain).connect(cntxt.destination)
      } catch (e) {}
    }
  }

  return (
    <>
      <Helmet>
        <meta content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no' name='viewport' />
      </Helmet>
      <div
        className={classes.container}
        onMouseDown={mouseDown}
        onMouseUp={mouseUp}
        onMouseMove={handleMouseMove}
        onTouchStart={mouseDown}
        onTouchEnd={mouseUp}
        onTouchMove={handleTouchMove}
      >
        <>
          <Box textAlign='center'>
            <Theremin height={400} width={200} />
          </Box>
        </>
      </div>
    </>
  )
}
