import React from 'react'

import { Layout } from 'components/layouts/main-layout'
import { ThereminEgg } from 'components/ministry-of-passover/theremin'


export default () => {
  return (
    <Layout pageName='secret'>
      <ThereminEgg />
    </Layout>
  )
}
