import React, { SVGProps } from 'react';

export const Theremin = (props: SVGProps<SVGSVGElement>) => (
  <svg x="0px" y="0px" viewBox="0 0 470 470" xmlSpace="preserve" {...props}>
    <circle cx={287.5} cy={350} r={7.5} />
    <circle cx={317.5} cy={350} r={7.5} />
    <circle cx={257.5} cy={350} r={7.5} />
    <path d="M462.5 52.5A7.5 7.5 0 00455 60v282.5h-15V310a7.5 7.5 0 00-7.5-7.5h-290a7.5 7.5 0 00-7.5 7.5v12.5H47.5C21.309 322.5 0 343.809 0 370s21.309 47.5 47.5 47.5c23.64 0 43.302-17.359 46.909-40H135V390a7.5 7.5 0 007.5 7.5H155v7.5a7.5 7.5 0 0015 0v-7.5h235v7.5a7.5 7.5 0 0015 0v-7.5h12.5a7.5 7.5 0 007.5-7.5v-32.5h22.5a7.5 7.5 0 007.5-7.5V60a7.5 7.5 0 00-7.5-7.5zM135 362.5H87.5A7.5 7.5 0 0080 370c0 17.92-14.58 32.5-32.5 32.5S15 387.92 15 370s14.58-32.5 32.5-32.5H135v25zm85 20h-70v-65h70v65zm205 0h-70V340a7.5 7.5 0 00-15 0v42.5H235v-65h190v65z" />
  </svg>
)
